* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

body {
  background-color: #f5f5f5;
  font-family: 'PingFang SC', 'Helvetica Neue', Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

.App {
  min-height: 100vh;
  background-image: url('/public/bg.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 20px;
}

.App-header, main {
  width: 80%;
  max-width: 1200px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}

.App-header {
  text-align: center;
}

h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.search-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.search-container input {
  padding: 10px 15px;
  font-size: 16px;
  border: 2px solid transparent;
  border-radius: 20px 0 0 20px;
  width: 70%;
  max-width: 300px;
  outline: none; /* 移除默认的焦点���廓 */
  transition: border-color 0.3s ease; /* 添加过渡效果 */
}

.search-container input:focus {
  border-color: #FFD700; /* 使用黄色作为焦点边框颜色 */
  box-shadow: 0 0 0 2px rgba(255, 215, 0, 0.2); /* 添加柔和的阴影效果 */
}

.search-container button {
  padding: 10px 20px;
  background-color: #FFD700;
  color: #333;
  border: 2px solid #FFD700;
  border-radius: 0 20px 20px 0;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.search-container button:hover,
.search-container button:focus {
  background-color: #FFC000;
  border-color: #FFC000;
}

main {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 1200px;
}

.movie-item {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  cursor: pointer;
}

.movie-item:hover {
  transform: translateY(-5px);
}

.movie-item img {
  width: 100%;
  height: auto;
  aspect-ratio: 2/3;
  object-fit: cover;
}

.movie-item h3 {
  padding: 10px;
  margin: 0;
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.movie-detail {
  position: relative;
  padding-top: 10px;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.movie-detail h2 {
  margin: 0;
  padding: 10px 15px;
  font-size: 18px;
  border-bottom: 1px solid #e0e0e0;
  color: #000;
}

.player {
  margin-bottom: 20px;
}

.episodes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.episodes button {
  padding: 8px 12px;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.episodes button:hover,
.episodes button.active {
  background-color: #FFD700;
  color: #333;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #155db1;
}

.loading, .error {
  text-align: center;
  color: #666;
  font-size: 16px;
  margin-top: 20px;
}

.error {
  color: #d93025;
}

.search-results {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 20px;
  padding: 20px;
}

.movie-item {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  cursor: pointer;
}

.movie-item:hover {
  transform: translateY(-5px);
}

.movie-item img {
  width: 100%;
  height: auto;
  aspect-ratio: 2/3;
  object-fit: cover;
}

.movie-item h3 {
  padding: 10px;
  margin: 0;
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.movie-detail {
  position: relative;
  padding-top: 10px;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.movie-detail h2 {
  margin: 0;
  padding: 10px 15px;
  font-size: 18px;
  border-bottom: 1px solid #e0e0e0;
}

.player {
  margin-bottom: 20px;
}

.episodes {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.episodes button {
  padding: 8px 12px;
  background-color: #e0e0e0; /* 更深的灰色背景 */
  color: #333; /* 更深的文字颜色 */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.episodes button:hover {
  background-color: #d0d0d0;
}

.episodes button.active {
  background-color: #ff6b6b;
  color: white;
}

.episodes button.active:hover {
  background-color: #ff5252;
}

@media (max-width: 768px) {
  .App {
    padding: 10px;
  }

  .App-header, main {
    width: 95%;
    padding: 15px;
  }

  h1 {
    font-size: 1.8em;
    margin-bottom: 15px;
  }

  .search-container {
    margin-bottom: 15px;
  }

  .search-container input {
    padding: 8px 12px;
    font-size: 14px;
    width: 60%;
  }

  .search-container button {
    padding: 8px 12px;
    font-size: 14px;
  }

  main {
    padding: 10px;
    width: 95%;
  }

  .movie-item {
    padding: 8px;
    margin-bottom: 8px;
  }

  .episodes {
    gap: 5px;
  }

  .episodes button {
    padding: 6px 10px;
    font-size: 12px;
  }

  .player-container {
    margin-bottom: 10px;
  }

  video {
    width: 100%;
    height: auto;
  }

  .search-results {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 10px;
  }

  .movie-item {
    font-size: 12px;
  }

  .movie-item h3 {
    padding: 8px;
    font-size: 12px;
  }
}

.movie-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #e0e0e0;
}

.movie-header h2 {
  margin: 0;
  font-size: 18px;
}

.share-button {
  position: absolute;
  top: 20px;
  right: 15px;
  background-color: #1e90ff;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.share-icon {
  margin-right: 5px;
}

.share-button:hover {
  background-color: #1a7ae1;
}